
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
import fileDownload from 'js-file-download';

import Layout from '@/components/Layout.vue';
import SVG from '@/components/SVG.vue';
import Table from '@/components/table/Table.vue';
import TableRow from '@/components/table/Row.vue';
import Loader from '@/components/Loader.vue';
import FileService from '@/services/file.service';
import { useRoute } from 'vue-router';
import File from '@/models/file.model';
import Store from '@/store';

export default defineComponent({
	components: {
		Layout,
		SVG,
		Table,
		TableRow,
		Loader,
	},

	setup() {
		const user = Store.get('user');
		const projectID: Ref<number | null> = ref(null);

		const publicInput: Ref = ref(null);
		const privateInput: Ref = ref(null);

		const loading = ref(true);

		const isExternalUser = computed(() => {
			let returnValue = false;

			user.value.role_names.forEach((role: string) => {
				if (role === 'External user') {
					returnValue = true;
				}
			});

			return returnValue;
		});

		const files: Ref<File[]> = ref([]);

		const privateFiles = computed(() => {
			if (!files.value.length) {
				return [];
			}

			return files.value.filter((file) => !file.public);
		});

		const publicFiles = computed(() => {
			if (!files.value.length) {
				return [];
			}

			return files.value.filter((file) => file.public);
		});

		onMounted(async () => {
			projectID.value = Number(useRoute().params.id);

			await FileService.get(projectID.value)
				.then((response) => {
					if (response) {
						files.value = response;
					}
				})
				.finally(() => {
					loading.value = false;
				});
		});

		function privateClick() {
			privateInput.value.click();
		}

		function publicClick() {
			publicInput.value.click();
		}

		async function uploadFiles(fileList: FileList, isPublic: boolean) {
			if (fileList.length && projectID.value) {
				loading.value = true;

				const formData = new FormData();
				formData.append('file', fileList[0]);
				formData.append('public', isPublic ? '1' : '0');

				await FileService.create(projectID.value, formData)
					.then((response) => {
						if (response) {
							response.public = !!Number(response.public);

							files.value = [...files.value, response];
						}
					})
					.finally(() => {
						loading.value = false;
					});
			}
		}

		async function downloadFile(file: File) {
			await FileService.download(file.id).then((response) => {
				if (response) {
					const fileName = `${file.original_name.replace(`.${file.extension}`, '')}.${file.extension}`;
					fileDownload(response, fileName);
				}
			});
		}

		async function removeFile(id: number) {
			if (!id) {
				return;
			}

			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			loading.value = true;

			await FileService.delete(id)
				.then((response) => {
					if (response) {
						files.value = files.value.filter((file: File) => file.id !== id);
					}
				})
				.finally(() => {
					loading.value = false;
				});
		}

		return {
			// data
			privateFiles,
			publicFiles,
			publicInput,
			privateInput,
			loading,
			isExternalUser,

			// functions
			uploadFiles,
			removeFile,
			downloadFile,
			privateClick,
			publicClick,
		};
	},
});
