import File from '@/models/file.model';
import Toast from '@/toast';
import axios from 'axios';

export default class FileService {
	public static async get(projectId: number): Promise<File[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/projects/${projectId}/files`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(
					`Ein feilur hendi, tá vit royndu at heinta allar fílurnar fyri verkætlanina við id ${projectId}`,
					error
				);

				Toast.error('Tað eydnaðist ikki at heinta fílarnar til verkætlanina');

				return false;
			});
	}

	public static async create(project_id: number, file: FormData): Promise<File> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/projects/${project_id}/files`, file, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at uploada eina fílu', error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async delete(id: number): Promise<{ status: string; message: string }> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/files/${id}`)
			.then((response) => {
				Toast.success('Fíla strikað', response.data.message);

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at strika fíluna við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async download(id: number): Promise<ArrayBuffer> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/files/${id}/download`, { responseType: 'arraybuffer' })
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta fíluna við id ${id}`, error);

				Toast.error('Tað eydnaðist ikki at heinta fíluna');

				return false;
			});
	}
}
